import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ConfigContext,
  ErrorContext,
  PrinterContext,
  STAGES,
  UserContext
} from '@/store'
import useDeviceRegistration from '@/hooks/useDeviceRegistration'
import TextHeader from '@/components/UI/TextHeader'
import classes from './ActivatingPage.module.scss'
import useDeviceAuthGrantApi from '@/hooks/useDeviceAuthGrantApi'
import useDeviceRtp from '@/hooks/useDeviceRtp'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import useAnalytics from '@/hooks/useAnalytics'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ActivationBenefits from '@/components/Activation/ActivationBenefits'
import { ANALYTICS, CONTENT_STACK_TYPES } from '@/store/Constants'
import useContentStack from '@/hooks/useContentStack'
import { getProductFlow } from '@/hooks/utils'
import { useShortcuts } from '@/hooks/useShortcuts'

const { MODES, SCREENS } = ANALYTICS

const MILLISECONDS_TO_TIMEOUT = 5 * 1000 * 60

const ActivatingPage = ({ doPairing }) => {
  const { onbpReleaseIristrigger: triggerIris } = useFlags()
  const { error, onError } = useContext(ErrorContext)
  const {
    getSessionId,
    programLevel,
    accountType,
    isHpPlusCountry
  } = useContext(UserContext)
  const {
    nextStage,
    sessionContext,
    isPreview,
    isEcpExperience,
    isLfp,
    shortcutsTenantManagerOutput,
    isScanSetup
  } = useContext(ConfigContext)
  const {
    isHpPlus,
    cloudId,
    claimTimestamp,
    name,
    location,
    bizModel,
    isSingleSku,
    pairingCode,
    uuid,
    serialNumber
  } = useContext(PrinterContext)
  const {
    isValid: isPairingValid,
    validate,
    confirm,
    errorCode: pairingError,
    clear
  } = useDeviceAuthGrantApi()
  const { rtpStatus, waitingForRtp, waitForRtp } = useDeviceRtp()
  const {
    callingShortcuts,
    isClaiming,
    result: claimResult,
    validateDevice,
    registerDevice
  } = useDeviceRegistration()
  const { createScanShortcut } = useShortcuts()
  const analyticsPrinterActivationProgress = useAnalytics(
    SCREENS.PRINTER_ACTIVATION_PROGRESS,
    false
  )
  const analyticsPrinterActivationSuccess = useAnalytics(
    SCREENS.PRINTER_ACTIVATION_SUCCESS
  )

  const [phase, setPhase] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [progressInterval, setProgressInterval] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [pairingStarted, setPairingStarted] = useState(false)
  const [currentStage, setCurrentStage] = useState(null)
  const [retryPage, setRetryPage] = useState(null)
  const [initiedAt] = useState(Date.now())
  const [handledRtpStatus, setHandledRtpStatus] = useState(false)
  const [hasClaimed, setHasClaimed] = useState(cloudId !== null)
  const TOTAL_PHASES = isHpPlus || triggerIris ? 5 : 4

  const onSuccess = useCallback(
    (additionalOutput = {}) => {
      if (error || showSuccess) return
      if (
        isScanSetup &&
        shortcutsTenantManagerOutput?.shortcutsTenantManager?.httpStatusCode !==
          201 &&
        !error
      )
        return
      setPhase(TOTAL_PHASES)
      setShowSuccess(true)
      if (isSingleSku) {
        analyticsPrinterActivationSuccess.fireScreenDisplayed({
          name: 'BizModel',
          detail: 'Factory_' + bizModel
        })
      } else {
        analyticsPrinterActivationSuccess.fireScreenDisplayed()
      }
      setTimeout(() => {
        const output = {
          onboardingContext: {
            programLevel:
              programLevel || sessionContext?.onboardingContext.programLevel
          },
          user: {
            selectedBizModel: bizModel
          },
          device: {
            uuid,
            serialNumber,
            cloudId,
            deviceLocation: location,
            deviceName: name
          },
          ...additionalOutput,
          ...(isScanSetup && shortcutsTenantManagerOutput)
        }
        nextStage({
          result: 'success',
          output,
          xCorrelationId: getSessionId()
        })
      }, 3000)
    },
    [
      TOTAL_PHASES,
      analyticsPrinterActivationSuccess,
      bizModel,
      cloudId,
      error,
      getSessionId,
      isScanSetup,
      isSingleSku,
      location,
      name,
      nextStage,
      programLevel,
      serialNumber,
      sessionContext,
      shortcutsTenantManagerOutput,
      showSuccess,
      uuid
    ]
  )

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.activating,
    additional_params: {
      product_flow: getProductFlow(
        isHpPlus,
        isEcpExperience,
        isLfp,
        isScanSetup
      ),
      ...(!isScanSetup &&
        !isEcpExperience &&
        !isLfp && {
          hp_plus_country: isHpPlusCountry,
          tenant_type: accountType
        })
    }
  })

  /**
   *   Interval for updating progress bar
   *   Design: 90% in 30 seconds. 3% per second, or .75% every 1/4 second
   */
  useEffect(() => {
    if (!progressInterval) {
      const interval = setInterval(() => {
        // Still below 30 second threshold
        setCurrentProgress((prev) => prev + 0.75)
      }, 250)
      setProgressInterval(interval)
      return
    }
    if (phase === TOTAL_PHASES) {
      // Last phase, 100%
      setCurrentProgress(100)
      clearInterval(progressInterval)
      return
    }
    if (currentProgress >= 90) {
      // No more progress to make per design
      clearInterval(progressInterval)
    }
  }, [phase, progressInterval, currentProgress, TOTAL_PHASES])

  // We will eventually implement subscription state API call to determine if isHpAllIn. ETA TBD.
  // /**
  //  *   Will execute on page load to check is HP All-in
  //  */
  // useEffect(() => {
  //   if (isPreview) return
  //   if (isHpAllIn) {
  //     onError({
  //       err: `OP_XXX_${CUSTOM_ERRORS.device_is_paas}_PR`,
  //       stg: STAGES.activation
  //     })
  //     return
  //   }
  // }, [isHpAllIn])

  /**
   *   1. Starts pairing w/ DAG
   *   2. Confirms pairing w/ DAG
   */
  const handlePairingProcess = useCallback(() => {
    setRetryPage(() => {
      return doPairing
    })
    setCurrentStage('DA_408_STAG00001_SP')
    setPhase(1)
    validate(pairingCode, false, (uuid) => {
      analyticsPrinterActivationProgress.fireScreenDisplayed({
        mode: MODES.SCREEN.INITIATION
      })
      setCurrentStage('DA_408_STAG00002_CP')
      setPhase(2)
      confirm(uuid)
    })
  }, [
    analyticsPrinterActivationProgress,
    confirm,
    doPairing,
    pairingCode,
    validate
  ])

  useEffect(() => {
    if (!pairingStarted && !hasClaimed && pairingCode) {
      setPairingStarted(true)
      handlePairingProcess()
      return
    }
    if (pairingError) {
      onError({
        err: pairingError,
        stg: STAGES.activation,
        behavior: doPairing
      })
      clear()
    }
  }, [
    clear,
    doPairing,
    handlePairingProcess,
    hasClaimed,
    onError,
    pairingCode,
    pairingError,
    pairingStarted
  ])

  /**
   * Runs after pairing.
   * 1. Validates IoT shadow
   * 2. Attempts to claim through register device
   */
  const handleClaimingProcess = useCallback(() => {
    setRetryPage(null)
    setPhase(3)
    setCurrentStage('UA_408_STAG00003_VD')
    analyticsPrinterActivationProgress.fireScreenDisplayed({
      mode: MODES.SCREEN.TOKEN
    })
    validateDevice(() => {
      setCurrentStage('UA_408_STAG00004_RD')
      setPhase(4)
      analyticsPrinterActivationProgress.fireScreenDisplayed({
        mode: MODES.SCREEN.IOT
      })
      registerDevice(() => {
        analyticsPrinterActivationProgress.fireScreenDisplayed({
          mode: MODES.SCREEN.REGISTER_DEVICE
        })
        if (isScanSetup) {
          createScanShortcut(cloudId, (sessionWasCreated) => {
            if (sessionWasCreated) {
              analyticsPrinterActivationProgress.fireScreenDisplayed({
                mode: MODES.SCREEN.CREATE_SHORTCUTS_SESSION
              })
            }
            analyticsPrinterActivationProgress.fireScreenDisplayed({
              mode: MODES.SCREEN.CREATE_SCAN_TO_EMAIL
            })
          })
        }
      })
    })
  }, [
    analyticsPrinterActivationProgress,
    cloudId,
    createScanShortcut,
    isScanSetup,
    registerDevice,
    validateDevice
  ])

  /**
   * Runs after claiming.
   * 1. E2E - Starts check for RTP
   */
  const handleSuccessfulClaim = useCallback(() => {
    if ((!isHpPlus && !triggerIris) || isSingleSku) {
      onSuccess()
      return
    }

    const rtpCheckNotStarted = !(rtpStatus !== null || waitingForRtp)
    if (rtpCheckNotStarted) {
      setRetryPage(null)
      setPhase(5)
      if (isHpPlus) {
        setCurrentStage('SD_408_STAG00005_RS')
        waitForRtp(claimTimestamp, cloudId)
        return
      }

      setCurrentStage('SD_408_STAG00005_GI')
    }

    const firmwareUpdateConfig = {
      updateType: 'auto'
    }
    onSuccess({ firmwareUpdateConfig })
  }, [
    claimTimestamp,
    cloudId,
    isHpPlus,
    isSingleSku,
    onSuccess,
    rtpStatus,
    triggerIris,
    waitForRtp,
    waitingForRtp
  ])

  useEffect(() => {
    if (isPairingValid && !isClaiming && phase < 4 && !hasClaimed) {
      handleClaimingProcess()
      return
    }
    if (cloudId && claimTimestamp && claimResult?.status === 'success') {
      setHasClaimed(true)
      handleSuccessfulClaim()
      return
    }
    if (claimResult?.status === 'error') {
      onError({ err: claimResult.message, stg: STAGES.activation })
    }
  }, [
    claimResult,
    claimTimestamp,
    cloudId,
    handleClaimingProcess,
    handleSuccessfulClaim,
    hasClaimed,
    isClaiming,
    isPairingValid,
    onError,
    phase
  ])

  /**
   * Status handler for RTP check
   */
  useEffect(() => {
    if (waitingForRtp || !rtpStatus || handledRtpStatus) {
      return
    }
    setHandledRtpStatus(true)
    if (rtpStatus.status === 'success') {
      analyticsPrinterActivationProgress.fireScreenDisplayed(
        {
          mode: MODES.SCREEN.RTP
        },
        false
      )
      onSuccess()
      return
    }
    if (rtpStatus.status === 'error') {
      onError({ err: rtpStatus.message, stg: STAGES.activation })
    }
  }, [
    rtpStatus,
    waitingForRtp,
    handledRtpStatus,
    analyticsPrinterActivationProgress,
    onSuccess,
    onError
  ])

  /**
   * Page load behavior for claim timeout
   */
  useEffect(() => {
    if (isPreview) return
    const milliseconds = MILLISECONDS_TO_TIMEOUT + initiedAt - Date.now()
    let timeout = setTimeout(() => {
      clearTimeout(timeout)
      if (showSuccess) return
      onError({
        err: currentStage,
        stg: STAGES.activation,
        behavior: retryPage
      })
    }, milliseconds)
    return () => {
      clearTimeout(timeout)
    }
  })

  if (!pageData) return <></>

  return (
    <div data-testid="activating-page">
      <TextHeader>
        {!showSuccess && !callingShortcuts && pageData.activating_header}
        {!showSuccess &&
          callingShortcuts &&
          pageData.intermediate_activating_header}
        {showSuccess && pageData.activated_header}
      </TextHeader>
      <div className={`${classes.activating} ${classes.container}`}>
        <ProgressIndicator
          appearance="linear"
          behavior="determinate"
          value={currentProgress}
        />
        <div className={classes.activatingText}>
          {!showSuccess && !callingShortcuts && (
            <>
              <p data-testid="activating-text">{pageData.activating_text}</p>
              {pageData?.phase_text && (
                <p data-testid="phases-text">{`${pageData.phase_text
                  .replace('[[current_phase]]', phase)
                  .replace('[[total_phases]]', TOTAL_PHASES)}`}</p>
              )}
            </>
          )}
          {!showSuccess && callingShortcuts && (
            <p data-testid="intermediate-activating-text">
              {pageData.intermediate_activating_text}
            </p>
          )}
          {showSuccess && (
            <p data-testid="success-text">{pageData.activated_text}</p>
          )}
        </div>
      </div>
      <ActivationBenefits copy={pageData.benefits} />
    </div>
  )
}

export default ActivatingPage
