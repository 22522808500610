import { useCallback, useRef } from 'react'
import { WhenJWebReady } from '@jarvis/jweb-core'
import { Logger } from '@/utils/Logger'

const useSubscriber = () => {
  const subscriberRef = useRef(null)
  const withSubscriberCheck = (callback) => {
    if (!subscriberRef.current) {
      Logger.error('Subscriber not initialized.')
      return
    }
    callback()
  }

  const createSubscriber = useCallback(async () => {
    try {
      const response = await WhenJWebReady
      const eventService = response?.Plugins?.EventService
      const subscriber = await eventService.createSubscriber()

      subscriberRef.current = {
        subscribe: subscriber.subscribe,
        unsubscribe: subscriber.unsubscribe
      }

      return subscriber
    } catch (error) {
      Logger.error('Error creating subscriber:', error)
    }
  }, [])

  const subscribe = useCallback((eventName, callback) => {
    withSubscriberCheck(() => {
      subscriberRef.current.subscribe(eventName, callback)
    })
  }, [])

  const unsubscribe = useCallback(() => {
    withSubscriberCheck(() => {
      subscriberRef.current.unsubscribe()
    })
  }, [])

  return {
    createSubscriber,
    subscribe,
    unsubscribe,
    subscriberRef
  }
}

export default useSubscriber
