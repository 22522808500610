import { UiMgtSvcClient } from '@jarvis/web-stratus-client'

export class UiMgtClient extends UiMgtSvcClient {
  getProgramLocales(programIdList, headers = {}) {
    return this.jarvisWebHttpInstance.get({
      url: '/programlocales',
      params: {
        programIdList: programIdList.join(',')
      },
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      data: null
    })
  }
}
