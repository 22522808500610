import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { ConfigContext, ErrorContext, PrinterContext, STAGES } from '@/store'
import { ShortcutsTenantMgrSvcClient } from '@jarvis/web-stratus-client'
import { Logger } from '@/utils/Logger'
import { CUSTOM_ERRORS } from '@/store/ErrorContext'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import { SPLUNK_RUM_CUSTOM_EVENTS, SPLUNK_RUM_FIELDS } from '@/store/Constants'
import { useSplunkRum } from '@/hooks/useSplunkRum'
import useCookies from 'react-use-cookie'
import { useODOutput } from '@/hooks/useODOutput'

const CREATE_SHORTCUT_ATTEMPTS = 3

const validateFalseSuccessResponse = (response) => {
  if (response?.data?.status === 500) {
    const error = new Error()
    error.response = {
      data: response?.data,
      status: 500
    }
    throw error
  }
}

const mountDataToCreateShortcut = (deviceUuid) => {
  return {
    deviceUuid: deviceUuid,
    shortcut: {
      category: ['email'],
      smartTask: {
        jobName: 'Scan to Admin',
        fileType: 'pdf',
        smartTaskConfig: {
          email: {
            tos: [],
            subject: 'You have new files',
            message:
              'Please check the attached files that have been sent to you.'
          }
        }
      }
    }
  }
}

export function useShortcuts() {
  const { stack, authProvider, setShortcutsTenantManagerOutput } = useContext(
    ConfigContext
  )
  const { cloudId, modelName, uuid } = useContext(PrinterContext)
  const { onError } = useContext(ErrorContext)

  const shortcutsTenantManagerClient = useRef(null)
  const [callingShortcuts, setCallingShortcuts] = useState(false)

  const { shortcutsErrorOutput, shortcutsSuccessOutput } = useODOutput()
  const { publishSpanEvent: publishSessionSpanEvent } = useSplunkRum(
    SPLUNK_RUM_CUSTOM_EVENTS.SHORTCUTS_SESSION_CREATION_ERROR
  )
  const { publishSpanEvent: publishShortcutCreationError } = useSplunkRum(
    SPLUNK_RUM_CUSTOM_EVENTS.SHORTCUT_CREATION_ERROR
  )
  const [scanSetupCompleteCookie, setScanSetupCompleteCookie] = useCookies(
    'scanSetupComplete'
  )

  const addPrinterToShortcutsCookies = useCallback(() => {
    try {
      const scanSetupCompleteCookieParsed = scanSetupCompleteCookie
        ? JSON.parse(scanSetupCompleteCookie)
        : []

      scanSetupCompleteCookieParsed.push({
        cloudID: cloudId,
        modelName
      })

      setScanSetupCompleteCookie(JSON.stringify(scanSetupCompleteCookieParsed))
    } catch (e) {
      Logger.error('scanSetupCompleteCookie error:', e)
    }
  }, [cloudId, modelName, scanSetupCompleteCookie, setScanSetupCompleteCookie])

  const createShortcutSession = useCallback(async () => {
    try {
      await shortcutsTenantManagerClient.current.createSession(uuid)
      return true
    } catch (error) {
      publishSessionSpanEvent({
        [SPLUNK_RUM_FIELDS.RESPONSE_BODY]: JSON.stringify(
          error?.response?.data
        ),
        [SPLUNK_RUM_FIELDS.RESPONSE_STATUS]: error?.response?.status
      })
      return false
    }
  }, [uuid, publishSessionSpanEvent])

  const createScanShortcut = useCallback(
    async (cloudId, onSuccess = EMPTY_FUNCTION) => {
      setCallingShortcuts(true)
      const sessionWasCreated = await createShortcutSession()

      let attempts = 0
      while (attempts < CREATE_SHORTCUT_ATTEMPTS) {
        Logger.log(`Creating scan shortcut, attempt: ${attempts}`)
        try {
          const response = await shortcutsTenantManagerClient.current.createShortcut(
            mountDataToCreateShortcut(uuid),
            'oobe'
          )
          validateFalseSuccessResponse(response)
          addPrinterToShortcutsCookies()
          setShortcutsTenantManagerOutput(
            shortcutsSuccessOutput(response.data.id)
          )
          onSuccess(sessionWasCreated)
          break
        } catch (error) {
          attempts += 1
          if (attempts === CREATE_SHORTCUT_ATTEMPTS) {
            const { data, status } = error?.response || {}
            publishShortcutCreationError({
              [SPLUNK_RUM_FIELDS.RESPONSE_BODY]: JSON.stringify(data),
              [SPLUNK_RUM_FIELDS.RESPONSE_STATUS]: status
            })
            setShortcutsTenantManagerOutput(shortcutsErrorOutput(status, data))
            onError({
              err: `OP_XXX_${CUSTOM_ERRORS.shortcut_creation_failed}_XX`,
              stg: STAGES.activation
            })
          }
        }
      }
      setCallingShortcuts(false)
    },
    [
      uuid,
      createShortcutSession,
      addPrinterToShortcutsCookies,
      shortcutsSuccessOutput,
      setShortcutsTenantManagerOutput,
      onError,
      publishShortcutCreationError,
      shortcutsErrorOutput
    ]
  )

  useEffect(() => {
    shortcutsTenantManagerClient.current = new ShortcutsTenantMgrSvcClient(
      stack,
      authProvider
    )
  }, [stack, authProvider])

  return useMemo(
    () => ({
      callingShortcuts,
      createScanShortcut
    }),
    [callingShortcuts, createScanShortcut]
  )
}
