import React, { useContext } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'
import { getRedirectionPath } from '@/utils/Functions'
import { SelectSupportedLocaleLayout } from './Layouts/SelectSupportedLocaleLayout'

const { BUTTONS, SCREENS } = ANALYTICS
const UnsupportedLocaleError = ({ error, children }) => {
  const { offer, bizModel } = useContext(PrinterContext)
  const { contentStackLanguageSelection } = useContext(UserContext)
  const { isLfp, isEcpExperience } = useContext(ConfigContext)

  const errorModalAnalytics = useAnalytics(SCREENS.UNSUPPORTED_LOCALE)
  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CONTINUE, {
      detail: contentStackLanguageSelection
    })
    if (isEcpExperience) {
      window.location.pathname = `/${contentStackLanguageSelection}/pn`
    } else {
      window.location.pathname = getRedirectionPath(
        offer,
        bizModel,
        contentStackLanguageSelection,
        isLfp
      )
    }
  }

  errorModalAnalytics.fireModalWindowDisplayed({
    detail: error,
    name: ANALYTICS.ERROR_CODE
  })

  return <>{children({ primaryClick, Layout: SelectSupportedLocaleLayout })}</>
}

export default UnsupportedLocaleError
