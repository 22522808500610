import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ConfigContext } from './ConfigContext'
import {
  ACCOUNT_TYPE_COMPANY,
  ACCOUNT_TYPE_MPS_COMPANY,
  ACCOUNT_TYPE_PERSONAL,
  SUPPORTED_LOCALE_SELECTION_ITEMS
} from './Constants'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import useLocales from '@/hooks/useLocales'
import { UserMgtSvcClientv3 } from '@jarvis/web-stratus-client'
import { Logger } from '@/utils/Logger'
import { STATUS } from '@/hooks/useDeltaAccessCodeValidation'

export const UserContext = React.createContext({
  accountType: null,
  organization: null,
  organizationDescription: null,
  organizationId: null,
  isPersonalAccount: false,
  isCompanyAccount: false,
  isHpPlusCountry: false,
  usingExistingDomain: false,
  selectedCountry: null,
  programLevel: null,
  userEmail: null,
  hpPlusOfferAccepted: false,
  contentStackLanguageSelection: null,
  setAccountType: EMPTY_FUNCTION,
  setOrganization: EMPTY_FUNCTION,
  setOrganizationDescription: EMPTY_FUNCTION,
  setOrganizationId: EMPTY_FUNCTION,
  setUsingExistingDomain: EMPTY_FUNCTION,
  setSelectedCountry: EMPTY_FUNCTION,
  getSessionId: EMPTY_FUNCTION,
  setProgramLevel: EMPTY_FUNCTION,
  setUserEmail: EMPTY_FUNCTION,
  setHpPlusOfferAccepted: EMPTY_FUNCTION,
  setContentStackLanguageSelection: EMPTY_FUNCTION,
  hpPlusLocaleErrorCode: null,
  hpPlusLocales: null,
  parentAccountResourceId: null,
  deltaAccessStatus: null,
  setDeltaAccessStatus: EMPTY_FUNCTION
})

const UserProvider = (props) => {
  const {
    userIsLoggedIn,
    isEcpExperience,
    getStoreData,
    setStoreData,
    sessionContext,
    updateStageContext,
    country,
    hpPlusCountries,
    stack,
    authProvider,
    language
  } = useContext(ConfigContext)
  const [loadedFromStore, setLoadedFromStore] = useState(false)
  const [organization, setOrganization] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [organizationDescription, setOrganizationDescription] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)
  const [accountType, setAccountType] = useState(null)
  const [hpPlusOfferAccepted, setHpPlusOfferAccepted] = useState(false)
  const [usingExistingDomain, setUsingExistingDomain] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedCountryISO, setSelectedCountryISO] = useState(null)
  const [programLevel, setProgramLevel] = useState(null)
  const { getLocales, locales, errorCode } = useLocales()
  const [parentAccountResourceId, setParentAccountResourceId] = useState(null)
  const [
    contentStackLanguageSelection,
    setContentStackLanguageSelection
  ] = useState(['us/en'])
  const [deltaAccessStatus, setDeltaAccessStatus] = useState(STATUS.NOT_STARTED)

  useEffect(() => {
    SUPPORTED_LOCALE_SELECTION_ITEMS.forEach((obj) => {
      if (obj.value.includes(language)) {
        setContentStackLanguageSelection([obj.value])
      }
    })
  }, [language])

  if (
    isEcpExperience &&
    sessionContext?.tenant?.parentAccountResourceId &&
    !parentAccountResourceId
  ) {
    setParentAccountResourceId(sessionContext?.tenant?.parentAccountResourceId)
  }

  if (sessionContext?.tenant?.type && !accountType) {
    setAccountType(sessionContext.tenant.type)
  }

  const getSessionId = () => {
    let id
    try {
      id = sessionContext.xCorrelationId
    } catch {
      id = `ONBP-${uuidv4()}`
    }
    return id
  }

  const resetUserData = () => {
    setOrganization(null)
    setOrganizationDescription(null)
    setOrganizationId(null)
    setAccountType(null)
    setUsingExistingDomain(false)
    setSelectedCountry(null)
    setSelectedCountryISO(null)
    setParentAccountResourceId(null)
    setHpPlusOfferAccepted(false)
  }

  useEffect(() => {
    if (stack && authProvider && userIsLoggedIn && !userEmail) {
      new UserMgtSvcClientv3(stack, authProvider)
        .getCurrentActiveUser()
        .then((response) => {
          setUserEmail(response.data.email.value)
        })
        .catch((error) => {
          Logger.error('getCurrentActiveUser error:', error)
          setUserEmail(null)
        })
    }
  }, [stack, authProvider, userIsLoggedIn, userEmail])

  useEffect(() => {
    if (!loadedFromStore) {
      const userData = getStoreData('user')
      if (userData) {
        setAccountType(userData.accountType)
        setOrganization(userData.organization)
        setOrganizationDescription(userData.organizationDescription)
        setOrganizationId(userData.organizationId)
        setSelectedCountry(userData.selectedCountry)
        setSelectedCountryISO(userData.selectedCountryISO)
        setParentAccountResourceId(userData.parentAccountResourceId)
      }
      setLoadedFromStore(true)
    }
  }, [loadedFromStore, getStoreData])

  useEffect(() => {
    if (loadedFromStore) {
      setStoreData('user', {
        accountType,
        organization,
        organizationDescription,
        selectedCountry,
        selectedCountryISO,
        programLevel,
        parentAccountResourceId
      })
      updateStageContext({
        organization,
        programLevel
      })
    }
  }, [
    accountType,
    organization,
    organizationDescription,
    loadedFromStore,
    setStoreData,
    updateStageContext,
    programLevel,
    parentAccountResourceId
  ])

  useEffect(() => {
    if (userIsLoggedIn && !locales) {
      getLocales()
    }
  }, [userIsLoggedIn, getLocales, locales])

  const isPersonalAccount = accountType === ACCOUNT_TYPE_PERSONAL
  const isCompanyAccount =
    [ACCOUNT_TYPE_MPS_COMPANY, ACCOUNT_TYPE_COMPANY].indexOf(accountType) > -1

  const isHpPlusCountry =
    hpPlusCountries.indexOf(selectedCountryISO || country) > -1

  const userState = {
    isPersonalAccount,
    isCompanyAccount,
    isHpPlusCountry,
    accountType,
    hpPlusOfferAccepted,
    organization,
    organizationDescription,
    organizationId,
    setOrganization,
    setOrganizationDescription,
    setOrganizationId,
    usingExistingDomain,
    selectedCountry,
    selectedCountryISO,
    contentStackLanguageSelection,
    setAccountType,
    setHpPlusOfferAccepted,
    setSelectedCountry,
    setSelectedCountryISO,
    getSessionId,
    setUsingExistingDomain,
    loadedFromStore,
    resetUserData,
    programLevel,
    setProgramLevel,
    supportedCountries: locales,
    supportedCountriesErrorCode: errorCode,
    parentAccountResourceId,
    userEmail,
    setUserEmail,
    setContentStackLanguageSelection,
    deltaAccessStatus,
    setDeltaAccessStatus
  }

  return (
    <UserContext.Provider value={userState}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
