import React, { useContext, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { ConfigContext, ErrorContext, PrinterContext } from './store'
import {
  AccountCreationPage,
  AccountTypePage,
  ActivatingPage,
  ActivationPage,
  NamePrinterPage,
  PairingCodePage,
  ValuePropPage,
  FirmwareUpdatePage,
  FirmwareUpdateChoicePage,
  HpPlusBenefitsPage,
  InstallDriversPage,
  InstallDriversWindowsPage,
  FirstPrintPage,
  FinishSetupEcpPage,
  InstallPrintingSoftwarePage,
  HpSmartAdminPage,
  PartnerLinkPage,
  LandingPage,
  HPSmartAdminValuePropPage
} from './pages'
import Background from './components/UI/Background'
import ErrorModal from './components/UI/Errors/ErrorModal'
import ContentStackErrorModal from './components/UI/Errors/ContentStackErrorModal'
import GenericModal, {
  GENERIC_MODAL_TYPES
} from '@/components/UI/Modals/GenericModal'
import LoadingBackdrop from '@/components/UI/LoadingBackdrop'
import DeltaCodePage from '@/pages/Web/Account/DeltaCodePage'
import { useDeltaAccessCodeValidation } from '@/hooks/useDeltaAccessCodeValidation'

const App = () => {
  const {
    error,
    allErrorDataAvailable,
    reconsiderModalVisible,
    isLegacy
  } = useContext(ErrorContext)
  const {
    backgroundClassName,
    isWindows,
    isEcpExperience,
    isBinaryApp,
    isLoading,
    isSmb
  } = useContext(ConfigContext)
  const { isSingleSku } = useContext(PrinterContext)
  const { showDeltaCodePage } = useDeltaAccessCodeValidation()
  const [needsPairing, setNeedsPairing] = useState(false)
  const { path: routePath } = useRouteMatch()

  const path = routePath !== '/' ? routePath : ''
  const showPairing = () => {
    setNeedsPairing(true)
  }

  const hidePairing = () => {
    setNeedsPairing(false)
  }

  const showError = !reconsiderModalVisible && error && allErrorDataAvailable

  const renderSmbFinishPage = () =>
    isWindows ? <InstallDriversWindowsPage /> : <InstallDriversPage />

  if (showDeltaCodePage) {
    return <DeltaCodePage />
  }

  return (
    <Background className={backgroundClassName}>
      {isLoading && <LoadingBackdrop />}
      <GenericModal
        type={GENERIC_MODAL_TYPES.RECONSIDER_MODAL}
        visible={reconsiderModalVisible}
      />
      {showError && isLegacy && <ErrorModal />}
      {showError && !isLegacy && <ContentStackErrorModal />}
      <Route path={`${path}/activating`}>
        {!needsPairing && <ActivatingPage doPairing={showPairing} />}
        {needsPairing && <PairingCodePage hidePairing={hidePairing} />}
      </Route>
      <Route
        // Keeping this only for demo purposes. In prod, this is controlled by JSHELL
        path={[
          `${path}/activate`,
          `${path}/connect`,
          `${path}/onboard`,
          `${path}/startscan`,
          `${path}/value-prop`
        ]}
        component={isBinaryApp ? LandingPage : ValuePropPage}
      />
      <Route path={`${path}/account-type`} component={AccountTypePage} />
      <Route path={`${path}/assign-organization`}>
        <AccountCreationPage />
      </Route>
      <Route path={`${path}/user-onboarding`}>
        <AccountCreationPage isUserOnboarding={true} />
      </Route>
      <Route path={`${path}/auto-firmware-update-notice`}>
        {isSingleSku || (isBinaryApp && !isSmb) ? (
          <FirmwareUpdateChoicePage />
        ) : (
          <FirmwareUpdatePage />
        )}
      </Route>
      <Route path={`${path}/pairing-code`} component={PairingCodePage} />
      <Route
        path={[`${path}/name-printer`, `${path}/name-and-location`]}
        component={NamePrinterPage}
      />
      <Route path={`${path}/hp-plus-benefits`} component={HpPlusBenefitsPage} />
      <Route path={`${path}/activation`} component={ActivationPage} />
      <Route path={`${path}/finish-setup`}>
        {!isEcpExperience && renderSmbFinishPage()}
        {isEcpExperience && <FinishSetupEcpPage />}
      </Route>
      <Route path={`${path}/first-print`} component={FirstPrintPage} />
      <Route path={`${path}/partner-link`} component={PartnerLinkPage} />
      <Route
        path={`${path}/go-to-dashboard`}
        component={isBinaryApp ? HPSmartAdminValuePropPage : HpSmartAdminPage}
      />
      <Route
        path={`${path}/install-printing-sw`}
        component={InstallPrintingSoftwarePage}
      />
    </Background>
  )
}
export default App
