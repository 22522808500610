import React, { useMemo, useState } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import AssetsProviderFactory from '@/assets/AssetsProviderFactory'
import { createBrowserHistory } from 'history'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import {
  ECP_EXPERIENCE,
  ENTRY_SCAN_SETUP,
  ENTRY_APP_OOBE,
  ENTRY_EARLY_OOBE,
  PRODUCT_FAMILY_LFP,
  SMB_EXPERIENCE,
  ENTRY_HPX_OOBE
} from './Constants'
import { Logger } from '@/utils/Logger'
import {
  UiTheme,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

const LOCAL_STORAGE_KEY = 'portal-oobe'

const getStoreData = (subkey = null) => {
  const text = localStorage.getItem(LOCAL_STORAGE_KEY)
  let json = text ? JSON.parse(text) : {}
  if (!subkey) {
    return json
  }
  return json[subkey]
}

const setStoreData = (subkey, value) => {
  const json = getStoreData()
  json[subkey] = value
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(json))
}

export const ConfigContext = React.createContext({
  authProvider: null,
  sessionContext: null,
  personalFlowDisabled: false,
  nextStage: EMPTY_FUNCTION,
  updateStageContext: EMPTY_FUNCTION,
  publishCdmEvent: EMPTY_FUNCTION,
  country: null,
  language: null,
  locale: null,
  navigation: null,
  hpPlusCountries: [],
  stack: Stack.pie,
  setSidebarVisibility: EMPTY_FUNCTION,
  getStoreData: null,
  setStoreData: null,
  getText: null,
  getTextTree: null,
  updateHpPlusCountries: EMPTY_FUNCTION,
  nextStageError: null,
  setNextStageError: EMPTY_FUNCTION,
  isEcpExperience: null,
  experience: '',
  userIsLoggedIn: false,
  isWindows: false,
  logout: EMPTY_FUNCTION,
  isLfp: false,
  isAppOobe: false,
  isEarlyOobe: false,
  isBinaryApp: false,
  isScanSetup: false,
  serviceRouting: null,
  appSessionId: null,
  isLoading: false,
  setIsLoading: EMPTY_FUNCTION,
  setTenant: EMPTY_FUNCTION,
  isHpx: false
})
const DEFAULT_COUNTRY = 'us'
const DEFAULT_LANGUAGE = 'en'

const ConfigProvider = (props) => {
  const {
    authProvider = null,
    store = null,
    analytics = null,
    localization = {
      country: DEFAULT_COUNTRY,
      language: DEFAULT_LANGUAGE,
      enabled: false
    },
    stack = Stack.pie,
    serviceRouting = null,
    sessionInterface,
    tenantHandlerInterface
  } = props
  const country = localization.country.toUpperCase()
  const language = localization.language
  const locale =
    localization.language + '-' + localization.country.toLowerCase()
  const enabled = localization.enabled
  const history = createBrowserHistory({
    basename: enabled ? `/${country.toLowerCase()}/${language}` : `/`
  })
  const [navigation] = useState(props.navigation ? props.navigation : history)
  const [driverUrl, setDriverUrl] = useState(null)
  const [
    shortcutsTenantManagerOutput,
    setShortcutsTenantManagerOutput
  ] = useState(null)
  const [backgroundClassName, setBackgroundClassName] = useState(null)
  const [hpPlusCountries, setHpPlusCountries] = useState(
    getStoreData('config')?.hpPlusCountries || []
  )
  const { uiBrand } = useMultiUiBrandContext()
  const {
    onbpReleasePersonalaccounttypedisabled: personalFlowDisabled
  } = useFlags()
  const [nextStageError, setNextStageError] = useState(null)
  const [appSessionId, setAppSessionId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const assetsProvider = AssetsProviderFactory.create(language, country)
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  let isAndroid = /Android/i.test(navigator.userAgent)
  let isWindows = /Windows/i.test(navigator.userAgent)
  let publishCdmEvent = EMPTY_FUNCTION
  let nextStage = EMPTY_FUNCTION

  const sessionContext = store?.state?.onboarding?.sessionContext
  const updateStageContext =
    store?.state?.onboarding?.updateStageContext || EMPTY_FUNCTION
  const setSidebarVisibility =
    store?.state?.onboarding?.setSidebarVisibility || EMPTY_FUNCTION
  const experience = sessionContext?.onboardingContext?.experience
  const isEcpExperience = experience === ECP_EXPERIENCE

  const { productFamily, entry } = useMemo(
    () => sessionContext?.onboardingContext || {},
    [sessionContext]
  )

  const isLfp = useMemo(() => productFamily === PRODUCT_FAMILY_LFP, [
    productFamily
  ])

  const isScanSetup = useMemo(() => entry === ENTRY_SCAN_SETUP, [entry])

  const isAppOobe = useMemo(() => entry === ENTRY_APP_OOBE, [entry])

  const isEarlyOobe = useMemo(() => entry === ENTRY_EARLY_OOBE, [entry])

  const isHpx = useMemo(() => uiBrand === UiTheme.hpx, [uiBrand])

  const isBinaryApp = useMemo(() => isAppOobe || isEarlyOobe || isHpx, [
    isAppOobe,
    isEarlyOobe
  ])

  const isSmb = useMemo(() => experience?.toLowerCase?.() === SMB_EXPERIENCE, [
    experience
  ])

  if (analytics?.publishCdmEvents) {
    publishCdmEvent = (events, metadata) => {
      events = Array.isArray(events) ? events : [events]
      metadata
        ? analytics.publishCdmEvents(events, metadata)
        : analytics.publishCdmEvents(events)
    }
  }

  if (store?.state?.onboarding?.nextStage) {
    nextStage = async (...params) => {
      try {
        await store.state.onboarding.nextStage(...params)
      } catch {
        Logger.error('nextStage error')
        setNextStageError(true)
      }
    }
  }

  const _getAppSessionId = async () => {
    if (serviceRouting) {
      try {
        const serviceLaunchOptions = await serviceRouting.getServiceInstanceLaunchOptions()
        const { serviceOptions } = serviceLaunchOptions
        if (serviceOptions?.appSessionId) {
          setAppSessionId(serviceOptions.appSessionId)
        }
      } catch (e) {
        Logger.error('getAppSessionId error:', e)
      }
    }
  }

  if (isBinaryApp && !appSessionId) {
    _getAppSessionId()
  }

  const updateHpPlusCountries = (countries) => {
    setStoreData('config', { hpPlusCountries: countries })
    setHpPlusCountries(countries)
  }

  const configState = {
    authProvider,
    sessionContext,
    personalFlowDisabled,
    nextStage,
    updateStageContext,
    publishCdmEvent,
    country,
    language,
    locale,
    navigation,
    driverUrl,
    setDriverUrl,
    shortcutsTenantManagerOutput,
    setShortcutsTenantManagerOutput,
    isMobile,
    isAndroid,
    isWindows,
    hpPlusCountries,
    stack,
    setSidebarVisibility,
    getStoreData,
    setStoreData,
    getText: (key, keys = {}) => {
      return assetsProvider.getText(key, keys)
    },
    getTextTree: (key) => {
      return assetsProvider.getTextTree(key)
    },
    backgroundClassName,
    setBackgroundClassName,
    updateHpPlusCountries,
    nextStageError,
    setNextStageError,
    isEcpExperience,
    experience,
    userIsLoggedIn: store?.state?.session?.isLoggedIn,
    logout: sessionInterface?.logout,
    isLfp,
    isAppOobe,
    isEarlyOobe,
    isBinaryApp,
    isScanSetup,
    serviceRouting,
    appSessionId,
    isLoading,
    setIsLoading,
    setTenant: tenantHandlerInterface?.setTenant,
    isSmb,
    isHpx
  }

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
